import { ReactNode } from "react";

export function MushafLayout({
  sidebarSlot,
  navSlot,
  leadSlot,
  titleSlot,
  children,
}: {
  sidebarSlot?: ReactNode | undefined;
  navSlot?: ReactNode | undefined;
  leadSlot?: ReactNode | undefined;
  titleSlot?: ReactNode | undefined;
  children?: ReactNode | undefined;
}) {
  return (
    <div className="mushaf-and-sidebar">
      {sidebarSlot}

      <div className="mushaf">
        {leadSlot}

        {titleSlot && (
          <h1
            dir="rtl"
            className="mushaf-title"
          >
            {titleSlot}
          </h1>
        )}

        <div style={{ flexGrow: 1 }}>{children}</div>

        {navSlot && <div style={{ flexGrow: 0 }}>{navSlot}</div>}
      </div>
    </div>
  );
}

function MushafLayoutContainer({ children }: { children: ReactNode }) {
  return <div className="mushaf-layout-container">{children}</div>;
}

MushafLayout.Container = MushafLayoutContainer;
