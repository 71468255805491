import { GraphContext, t } from "@muddakir/engine";
import { useContext, useMemo } from "react";
import { isToday, SessionStartingVerseLink } from "../Cycle";
import { getCycles, getSessions, isCurrentCycle } from "../graph";

export function CurrentCycleWidget() {
  const { graph, graphState } = useContext(GraphContext);
  const cycle = useMemo(() => {
    return getCycles(graph).find((cycle) => isCurrentCycle(graph, cycle.id));
  }, [graphState]);

  const session = useMemo(() => {
    if (!cycle) {
      return null;
    }

    return getSessions(graph, cycle.id, cycle.ranges).find((x) =>
      isToday(x.date!),
    );
  }, [cycle]);

  if (!cycle || !session) {
    return (
      <p dir="ltr">
        You do not have an active cycle. <a href="#/wird">Start one?</a>
      </p>
    );
  }

  return (
    <div>
      <SessionStartingVerseLink
        cycle={cycle.id}
        session={session.id}
        pages={session.pages}
        chapters={session.chapters}
      >
        {t(`Session ${session.number}`, `الجلسة (${session.number})`)}
      </SessionStartingVerseLink>
      {": "}
      {session.chapters.map((chapter) => chapter.name).join(", ")}
    </div>
  );
}
