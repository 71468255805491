import { t } from "@muddakir/engine";
import { useMemo } from "react";
import { MdPause, MdPlayArrow } from "react-icons/md";
import { prettyDuration } from "../prettyDuration";
import { SessionProgress } from "./progress";
import * as style from "./stopwatch.module.css";
import { useTimer } from "./useTimer";

export function Stopwatch({
  cycle,
  session,
  progress,
}: {
  cycle: GraphNodeId;
  session: number;
  progress: SessionProgress | null;
}) {
  const { elapsed, running, stop, start } = useTimer({ cycle, session });
  const eta = useMemo<number | null>(() => {
    if (!progress || progress.current === 0) {
      return null;
    }

    const timePerUnit = elapsed / progress.current;
    const expectedTotalTime = progress.total * timePerUnit;

    return Math.round(expectedTotalTime - elapsed);
  }, [progress]);

  return (
    <span className={style.container}>
      <span>{prettyDuration(elapsed)}</span>

      {running ? (
        <button
          type="button"
          className="frameless-icon-button"
          onClick={stop}
        >
          <MdPause />
        </button>
      ) : (
        <button
          type="button"
          className="frameless-icon-button"
          onClick={start}
        >
          <MdPlayArrow />
        </button>
      )}

      <span style={{ flexGrow: 1 }} />

      {eta && (
        <span>
          {t(
            `ETA: ${prettyDuration(eta || 0)}`,
            `الوقت المتبقي المتوقع: ${prettyDuration(eta || 0)}`,
          )}
        </span>
      )}
    </span>
  );
}
