import { GraphContext, PluginContext } from "@muddakir/engine";
import { classify, Unit } from "@muddakir/quran-db";
import { expand } from "@muddakir/quran-verse-range";
import {
  createUnitSiblingComputer,
  VerseTree,
} from "@muddakir/quran-verse-tree";
import { computeVerseHref } from "@muddakir/verse-anchors";
import { groupVersesBy } from "@muddakir/verse-grouping";
import React, { useContext, useMemo, useState } from "react";
import { LayoutProps, SelectionProps } from ".";
import {
  getVersesInCycle,
  getVersesInSession,
  isWirdCycle,
  VerseInWirdCycleSession,
} from "../graph";
import layout from "./layout";

export const useWirdDocument = ({
  path,
  cycle,
  session,
  scopeType,
  groupingUnit,
}: SelectionProps & {
  groupingUnit: Unit;
}): LayoutProps => {
  const { graph, graphState } = useContext(GraphContext);
  const { enabled: plugins } = useContext(PluginContext);
  const [error, setError] = useState<string | null>(null);

  const sessionVerses = useMemo<Record<
    VerseId,
    VerseInWirdCycleSession
  > | null>(() => {
    try {
      // if (session === undefined) {
      //   return indexById(getVersesInCycle(graph, cycle));
      // } else {
      // }
      return indexById(getVersesInSession(graph, cycle, session));
    } catch (e) {
      setError(() => (e as Error).message);
      return null;
    }
  }, [cycle, session, graphState]);

  const tree = React.useMemo(
    () =>
      new VerseTree((id) => {
        try {
          if (isWirdCycle(id)) {
            return getVersesInCycle(graph, cycle).map((x) => x.id);
          } else if (session !== undefined && id === String(session)) {
            return getVersesInSession(graph, cycle, session).map((x) => x.id);
          } else {
            return expand([id]);
          }
        } catch (e) {
          console.error(e);
          setError(
            () => `Could not find verses for cycle or session at "${id}"`,
          );
          return [];
        }
      }, createUnitSiblingComputer()),
    [cycle, session],
  );

  const { doc, node } = React.useMemo(() => {
    const node = tree.find(path);

    if (!node) {
      return { doc: null, node: null };
    }

    const hrefComputer = computeVerseHref({ node });
    const verses = node.verses
      .map((id) => sessionVerses?.[id])
      .filter((x) => !!x);
    const versesWithHref = verses.map((x) => ({ ...x, ...hrefComputer(x) }));

    const groupsAndVerses = groupVersesBy({
      groupingUnit,
      node,
      versePool: versesWithHref,
    });

    const doc = {
      type: "doc",
      content: layout(
        {
          graph,
          nodeType: classify(node.id),
          groupingUnit,
          scopeType,
          plugins,
        },
        groupsAndVerses,
      ),
    };

    console.log(doc);

    return { node, doc };
  }, [tree, path, graphState, groupingUnit]);

  return { error, doc, node, tree };
};

const indexById = <T extends { id: string }>(list: T[]) =>
  list.reduce(
    (acc, x) => {
      acc[x.id] = x;
      return acc;
    },
    {} as Record<string, T>,
  );
