import { ProseMirrorAppendixes } from "@muddakir/appendixes/pm/react";
import {
  dropSelectedChain,
  linkAdjacentVerseToChain,
  linkSelectionToChain,
  selectAdjacentVerse,
  unlinkCursorFromChain,
  unlinkSelectionFromChain,
} from "@muddakir/chains/pm/commands";
import { GraphContext, RoutingContext, usePreference } from "@muddakir/engine";
import { ProseMirrorFolders } from "@muddakir/folders/pm/react";
import Layout, { sidebarEnabledPreference } from "@muddakir/layout";
import { ProseMirrorM10t } from "@muddakir/m10t/pm";
import useM10tInURL from "@muddakir/m10t/useM10tInURL";
import { ProseMirrorMorph } from "@muddakir/morph/pm/react";
import useMorphModeInURL from "@muddakir/morph/useMorphModeInURL";
import { editNoteAtSelection, EditorProps } from "@muddakir/notes/pm";
import { ProseMirrorNotes } from "@muddakir/notes/react";
import { ProseMirrorFocusState } from "@muddakir/prosemirror-focus-state";
import {
  classify,
  isStartOfQuarter,
  Unit,
  unitsBetween,
} from "@muddakir/quran-db";
import { VerseTree, VerseTreeNode } from "@muddakir/quran-verse-tree";
import { Scribe } from "@muddakir/scribe";
import { ProseMirrorVerseAnchors } from "@muddakir/verse-anchors/pm/react";
import { ProseMirrorVerseGrouping } from "@muddakir/verse-grouping/pm/react";
import { groupingUnitPreference as groupingUnitPref } from "@muddakir/verse-grouping/preferences";
import { Jumper } from "@muddakir/verse-jumping";
import { jumpToAdjacentUnit } from "@muddakir/verse-jumping/pm/commands";
import { ProseMirrorVerseJumping } from "@muddakir/verse-jumping/pm/react";
import {
  ProseMirrorVerseMarkerKeymap,
  ProseMirrorVerseMarkerProps,
  ProseMirrorVerseMarkers,
} from "@muddakir/verse-markers";
import { collapseSelectionOnMark as collapseSelectionOnMarkPref } from "@muddakir/verse-markers/preferences";
import useVerseMarkersInURL from "@muddakir/verse-markers/useVerseMarkersInURL";
import { ProseMirrorVerseWRF } from "@muddakir/verse-wrf/pm/react";
import AbstractGraph from "graphology-types";
import { keymap } from "prosemirror-keymap";
import { ResolvedPos } from "prosemirror-model";
import { findVerseIdAtCursor } from "prosemirror-quran-schema";
import { ProseMirrorQuran } from "prosemirror-quran-schema/react";
import { PluginKey, TextSelection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ProseMirror, ProseMirrorContext } from "react-prosemirror";
import { QueryParams, ScopeSelection } from ".";
import { MushafLayout } from "./layout";
import { useBaseDocument } from "./load";
import MainMenuItems from "./menu";
import Nav from "./nav";
import Sidebar from "./sidebar";

interface NodeRel {
  text: string;
  href: string;
}

export function MushafEditor(props: QueryParams & ScopeSelection) {
  const m10t = useM10tInURL();
  const { error, doc, node, notes, scribe, tree } = useBaseDocument(
    { ...props, m10tEdit: m10t.m10tEdit },
    [m10t.m10tEdit],
  );
  const { anchor, path, scopeType } = props;
  const docId = node?.path?.join("/");
  const { graph } = React.useContext(GraphContext);
  const { navigate } = React.useContext(RoutingContext);
  const [sidebarEnabled] = usePreference(sidebarEnabledPreference);
  const [morphMode, setMorphMode] = useMorphModeInURL();
  const editor = useRef<EditorView | null>(null);
  const [groupingUnit] = usePreference(groupingUnitPref) as [Unit, any];
  const [verseMarkers, setVerseMarkers] = useVerseMarkersInURL([
    docId,
    m10t.m10tMode,
    groupingUnit,
  ]);
  const [clearSelectionOnMark] = usePreference(collapseSelectionOnMarkPref);
  // const m10tMode = usePreference(m10tModePref)[0];
  const notesBinding = useMemo(() => new PluginKey<EditorProps>(), []);

  const { describe, describeForTitle, describeRoot, abbrevRoot } = scribe;

  useEffect(() => {
    if (node) {
      document.title = describeForTitle(node);
    }
  }, [node]);

  if (error) {
    return (
      <Layout>
        <pre>{error}</pre>
      </Layout>
    );
  } else if (!node || !doc) {
    return <Layout />;
  }

  const dispatchKeyToEditor = (init: KeyboardEventInit) => () => {
    if (editor.current) {
      const wasAllowedToBubble = editor.current.dom.dispatchEvent(
        new KeyboardEvent("keydown", init),
      );
      return !wasAllowedToBubble;
    }
    return false;
  };

  const firstVerse: Verse | null = null; // TODO

  // TODO: what happens to @href when node.parent is null?
  const parentNodeRel: NodeRel | null =
    node.root !== node
      ? {
          text:
            node.parent === node.root
              ? abbrevRoot(node.parent, scopeType)!
              : describe(node.parent!)!,
          href: `${genHref(node.parent!)}?a=${node.id}`,
        }
      : null;

  const nextNodeRel = node.next ? genNavUnit(scribe, node.next) : null;
  const prevNodeRel = node.prev ? genNavUnit(scribe, node.prev) : null;
  const nextCousinNodeRel = computeCousinNodeRel({
    dir: 1,
    node,
    scribe,
    tree,
  });

  const prevCousinNodeRel = computeCousinNodeRel({
    dir: -1,
    node,
    scribe,
    tree,
  });

  return (
    <Layout>
      <MainMenuItems
        dispatchKeyToEditor={dispatchKeyToEditor}
        verseMarkers={verseMarkers}
      />

      <Jumper
        nextNode={nextNodeRel}
        nextCousinNode={nextCousinNodeRel}
        prevNode={prevNodeRel}
        prevCousinNode={prevCousinNodeRel}
        parentNode={parentNodeRel}
      />

      <MushafLayout
        titleSlot={
          <>
            {isRoot(node) ? describeRoot(node, scopeType) : describe(node)}

            {!!(firstVerse as Verse | null) &&
              isStartOfQuarter(firstVerse!.id) && (
                <span style={{ verticalAlign: "super", lineHeight: 1 }}>۞</span>
              )}
          </>
        }
        sidebarSlot={
          sidebarEnabled && (
            <Sidebar
              verseMarkers={verseMarkers}
              id={undefined}
            />
          )
        }
        navSlot={
          <Nav
            nextNode={nextNodeRel}
            prevNode={prevNodeRel}
            parentNode={parentNodeRel}
          />
        }
      >
        <ProseMirror
          doc={doc}
          ref={editor}
          debug
        >
          <ProseMirrorQuran />

          <ProseMirrorNotes
            binding={notesBinding}
            graph={graph}
            editNote={notes.editNote}
            note={notes.note}
            verseMarkers={verseMarkers}
          />

          <ProseMirrorVerseMarkers
            verseMarkers={verseMarkers}
            setVerseMarkers={setVerseMarkers}
          />

          <ProseMirrorVerseMarkerKeymap
            clearSelectionOnMark={clearSelectionOnMark}
            verseMarkers={verseMarkers}
            setVerseMarkers={setVerseMarkers}
          />

          <ProseMirrorM10t
            graph={graph}
            m10tEdit={m10t.m10tEdit}
            m10tMode={m10t.m10tMode}
            onCreateM10tGroup={m10t.createM10tGroup}
            onRemoveM10tGroups={m10t.removeM10tGroups}
            setM10tEdit={m10t.setM10tEdit}
            note={notes.note}
            editNote={notes.editNote}
            verseMarkers={verseMarkers}
            setVerseMarkers={setVerseMarkers}
          />

          <ProseMirrorFolders />
          <ProseMirrorMorph enabled={!!morphMode} />
          <ProseMirrorVerseAnchors anchor={anchor} />
          <ProseMirrorVerseJumping />
          <ProseMirrorAppendixes />
          <ProseMirrorVerseGrouping />
          <ProseMirrorVerseWRF />
          <ProseMirrorFocusState />

          <KeyBindings
            notesBinding={notesBinding}
            graph={graph}
            toggleMorphMode={() => {
              setMorphMode(!morphMode);
            }}
            verseMarkers={verseMarkers}
            setVerseMarkers={setVerseMarkers}
            clearSelectionOnMark={!!clearSelectionOnMark}
            onNavigateToVerse={(id) =>
              navigate(`/${path.concat([id]).join("/")}`)
            }
            onNavigateToParentNode={() =>
              parentNodeRel ? navigate(parentNodeRel.href) : Promise.reject()
            }
          />
        </ProseMirror>
      </MushafLayout>
    </Layout>
  );
}

const isRoot = (node: VerseTreeNode) => node.root === node;

type KeyBindingsProps = {
  graph: AbstractGraph;
  // editNote: (edge: GraphEdgeId | null) => void;
  notesBinding: PluginKey<EditorProps>;
  toggleMorphMode: () => void;
  clearSelectionOnMark: boolean;
  onNavigateToVerse: (id: VerseId) => unknown;
  onNavigateToParentNode: () => unknown;
} & ProseMirrorVerseMarkerProps;

function KeyBindings(props: KeyBindingsProps) {
  const { register, view } = useContext(ProseMirrorContext);
  const [dirOfVerseToCursorTo, moveCursorToAdjacentVerse] = useState<
    [ResolvedPos, 1 | -1] | null
  >(null);

  const binding = useRef<KeyBindingsProps>(props);
  binding.current = props;

  useEffect(() => {
    if (dirOfVerseToCursorTo !== null) {
      moveCursorToAdjacentVerse(() => null);

      // too lazy to find a more reasonable way to ensure the document has been
      // updated with the new chain before changing the cursor
      setTimeout(() => {
        selectAdjacentVerse(
          view!.state,
          view!.dispatch,
          view!.state.doc.resolve(dirOfVerseToCursorTo[0].pos),
          dirOfVerseToCursorTo[1],
        );
      }, 100);
    }
  }, [dirOfVerseToCursorTo]);

  useEffect(() => {
    return register({
      plugins: [
        // bus,
        keymap({
          "Enter"(state) {
            return editNoteAtSelection(
              state,
              props.notesBinding.getState(state)!,
            );
          },

          "r"() {
            binding.current.toggleMorphMode();
            return true;
          },

          "[": (_state, _dispatch, view) => jumpToAdjacentUnit(view!, "next"),
          "]": (_state, _dispatch, view) => jumpToAdjacentUnit(view!, "prev"),
          "j": (state) => {
            moveCursorToAdjacentVerse(() => [state.selection.$anchor, 1]);
            return true;
          },
          "k": (state) => {
            moveCursorToAdjacentVerse(() => [state.selection.$anchor, -1]);
            return true;
          },

          "Alt-Enter"(state) {
            const { onNavigateToVerse } = binding.current;

            if (typeof onNavigateToVerse === "function") {
              const verseId = findVerseIdAtCursor(state);

              if (verseId) {
                onNavigateToVerse(verseId);
                return true;
              }
            }

            return false;
          },

          "Alt-ArrowUp"() {
            const { onNavigateToParentNode } = binding.current;

            if (onNavigateToParentNode) {
              onNavigateToParentNode();
              return true;
            }

            return false;
          },

          "Alt-c"(state) {
            return linkSelectionToChain(state, binding.current.graph);
          },

          "Alt-v"(state) {
            const result = linkAdjacentVerseToChain(
              state,
              binding.current.graph,
            );

            if (typeof result === "boolean") {
              return result;
            } else if (result.err) {
              console.error(result.val);
              return true;
            } else {
              moveCursorToAdjacentVerse(() => [
                state.selection.$anchor,
                result.val,
              ]);

              return true;
            }
          },

          "Delete"(state) {
            if (dropSelectedChain(state, binding.current.graph)) {
              return true;
            }

            return unlinkSelectionFromChain(state, binding.current.graph);
          },

          "Alt-Delete"(state) {
            if (unlinkCursorFromChain(state, binding.current.graph)) {
              moveCursorToAdjacentVerse(() => [
                (state.selection as TextSelection).$cursor!,
                -1,
              ]);

              return true;
            }
            return false;
          },
        }),
      ],
    });
  }, []);

  return null;
}

function computeCousinNodeRel({
  dir,
  node,
  scribe,
  tree,
}: {
  dir: 1 | -1;
  node: VerseTreeNode;
  tree: VerseTree;
  scribe: Scribe;
}): NodeRel | null {
  if (!tree || !node.parent) {
    return null;
  }

  const uncle = dir === 1 ? node.parent.next : node.parent.prev;
  if (!uncle) {
    return null;
  }

  let typeOfCurrentUnit = classify(node.id);
  if (!typeOfCurrentUnit) {
    return null;
  }

  const [firstVerse] = uncle.verses;
  if (!firstVerse) {
    return null;
  }

  const [firstUnit] =
    typeOfCurrentUnit === Unit.Verse
      ? [firstVerse, firstVerse]
      : unitsBetween(typeOfCurrentUnit)([firstVerse, firstVerse]);

  const cousinNode = tree.find(uncle.path.concat([firstUnit]));
  if (!cousinNode) {
    return null;
  }

  return genNavUnit(scribe, cousinNode);
}

const genHref = (node: VerseTreeNode) => `#/${node.path.join("/")}`;
const genNavUnit = (scribe: Scribe, node: VerseTreeNode) => ({
  text: scribe.describeAmongSiblings(node)!,
  href: genHref(node),
});
