/** @jsxImportSource pmjsx */

import { MuddakirPlugin } from "#plugins";
import { getVerseText, Unit } from "@muddakir/quran-db";
import { ScopeType } from "@muddakir/quran-verse-range";
import { VerseLinkAttrs } from "@muddakir/verse-anchors";
import { VerseNumber } from "@muddakir/verse-anchors/pm/blocks";
import { borderLabelFor, shouldGroup } from "@muddakir/verse-grouping";
import { VerseGroupAndVerses } from "@muddakir/verse-grouping/types";
import AbstractGraph from "graphology-types";
import { VerseFolderAttrs } from ".";

type GroupAndVerses = VerseGroupAndVerses<
  {
    id: string;
    number: number;
    text: string;
  } & VerseLinkAttrs &
    VerseFolderAttrs
>;
type Props = {
  // anchor: string | undefined;
  graph: AbstractGraph;
  groupingUnit: Unit | null | undefined;
  nodeType: Unit | null;
  plugins: MuddakirPlugin[];
  scopeType: ScopeType;
  editedVerse: GraphEdgeId | undefined;
};

export default (props: Props, groups: GroupAndVerses[]) => {
  const nodes: JSX.Element[] = [];

  const shouldGroupVerses = shouldGroup({
    nodeType: props.nodeType,
    groupingUnit: props.groupingUnit,
    scopeType: props.scopeType,
    groups,
  });

  for (const [index, groupAndVerses] of groups.entries()) {
    const groupVerses = (
      <GroupVerses
        {...props}
        groupAndVerses={groupAndVerses}
      />
    );

    if (!shouldGroupVerses) {
      nodes.push(<pm:verse-group id={null!}>{groupVerses}</pm:verse-group>);
    } else if (groupAndVerses.group === null) {
      nodes.push(groupVerses);
    } else {
      const { group } = groupAndVerses;

      nodes.push(
        <pm:verse-group
          id={group.id}
          // @ts-expect-error
          type={group.type /* TODO: what is this supposed to map to? */}
          className={{
            "verse-group--spaced": true,
            "verse-group--lead": index === 0,
            "verse-group--separated": true,
          }}
        >
          {groupVerses}

          {group.href && (
            <pm:verse-group-anchor
              href={group.href!}
              altHref={group.altHref}
            >
              {`${borderLabelFor(group) || group.id}`}
            </pm:verse-group-anchor>
          )}
        </pm:verse-group>,
      );
    }
  }

  return nodes;
};

function GroupVerses({
  // anchor,
  groupAndVerses: { verses },
  groupingUnit,
  nodeType,
  editedVerse,
}: Props & { groupAndVerses: GroupAndVerses }) {
  const content: JSX.Node[] = [];

  for (const verse of verses) {
    const verseNode =
      editedVerse === verse.edge ? (
        <pm:verse-slice-editor
          className={"mushaf-m10t-verse-slice"}
          anchor={null}
          edge={verse.edge!}
          marker={[verse.id, ...verse.range]}
        >
          {getVerseText(verse.id)}
        </pm:verse-slice-editor>
      ) : (
        <pm:verse-slice edge={verse.edge!}>
          <pm:verse id={verse.id}>
            {getVerseText(verse.id).slice(...verse.range)}
          </pm:verse>
        </pm:verse-slice>
      );

    // content.push(
    //   <pm:verse-slice
    //     className={"mushaf-m10t-verse-slice"}
    //     anchor={null}
    //     edge={verse.edge!}
    //     marker={[verse.id, ...verse.range]}
    //     onChange={(edge: GraphEdgeId, marker: VerseMarker) => {
    //       console.log("handling change of folder verse:", marker);
    //       graph.setEdgeAttribute(edge, "range", marker.slice(1));
    //     }}
    //     onRemove={(edge: GraphEdgeId) => {
    //       console.log(
    //         "removing verse from folder",
    //         edge,
    //         verse.id,
    //         graph.target(edge),
    //         graph.source(edge),
    //       );
    //       graph.dropEdge(edge);
    //     }}
    //   />,
    // );

    // content.push(
    //   <pm:verse
    //     id={verse.id}
    //     range={(verse as VerseFolderAttrs).range}
    //   >
    //     {verse.text}
    //   </pm:verse>
    // ),

    content.push(
      verseNode,
      <VerseNumber
        anchor={undefined}
        nodeType={nodeType}
        groupingUnit={groupingUnit}
        verse={verse}
      />,
    );
  }

  return (
    <>
      <pm:justified>{content}</pm:justified>
    </>
  );
}
