import { t } from "@muddakir/engine";
import Layout from "@muddakir/layout";
import { SearchPalette } from "@muddakir/search/react";
import { CurrentCycleWidget } from "@muddakir/wird";
import * as style from "./index.module.css";

export function LandingPage() {
  return (
    <Layout>
      <Layout.BoundingBox>
        <Layout.ContentBox variant="solid">
          <div className={style.searchBox}>
            <h2 className={style.heading}>{t(`Study`, `الحفظ و التدبر`)}</h2>
            <SearchPalette onClose={() => {}} />
          </div>

          <div>
            <h2 className={style.heading}>
              {t(`Revise`, `المراجعة و التثبيت`)}
            </h2>
            <CurrentCycleWidget />
          </div>

          <div>
            <h2 className={style.heading}>{t(`Exercise`, `التمارين`)}</h2>
            <p>
              <em>TBD</em>
            </p>
          </div>
        </Layout.ContentBox>
      </Layout.BoundingBox>
    </Layout>
  );
}
