import { selectAdjacentVerse } from "@muddakir/chains/pm/commands";
import { KeyBinding } from "@muddakir/engine";
import { jumpToAdjacentUnit } from "@muddakir/verse-jumping/pm/commands";
import { keymap } from "prosemirror-keymap";
import { ResolvedPos } from "prosemirror-model";
import { findVerseIdAtCursor } from "prosemirror-quran-schema";
import { EditorView } from "prosemirror-view";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ProseMirrorContext } from "react-prosemirror";

type KeyBindingsProps = {
  onNavigateToVerse: (id: VerseId) => unknown;
  onNavigateToParentNode: () => unknown;
  onTrackMistakeInCurrentUnit: (verse: VerseId) => boolean;
};

interface State {
  view: EditorView | null;
}

export function WirdMushafKeyBindings(props: KeyBindingsProps) {
  const binding = useRef<KeyBindingsProps & State>(null!);
  const { register, view } = useContext(ProseMirrorContext);
  const [dirOfVerseToCursorTo, moveCursorToAdjacentVerse] = useState<
    [ResolvedPos, 1 | -1] | null
  >(null);

  const trackMistakeInVerseAtCursor = useCallback(() => {
    const verse = findVerseIdAtCursor(binding.current.view!.state);

    if (verse) {
      binding.current.onTrackMistakeInCurrentUnit(verse);
      return true;
    }

    return false;
  }, []);

  binding.current = { ...props, view };

  useEffect(() => {
    if (dirOfVerseToCursorTo !== null) {
      moveCursorToAdjacentVerse(() => null);

      // too lazy to find a more reasonable way to ensure the document has been
      // updated with the new chain before changing the cursor
      setTimeout(() => {
        selectAdjacentVerse(
          view!.state,
          view!.dispatch,
          view!.state.doc.resolve(dirOfVerseToCursorTo[0].pos),
          dirOfVerseToCursorTo[1],
        );
      }, 100);
    }
  }, [dirOfVerseToCursorTo]);

  useEffect(() => {
    return register({
      plugins: [
        keymap({
          "[": (_state, _dispatch, view) => jumpToAdjacentUnit(view!, "next"),
          "]": (_state, _dispatch, view) => jumpToAdjacentUnit(view!, "prev"),
          "j": (state) => {
            moveCursorToAdjacentVerse(() => [state.selection.$anchor, 1]);
            return true;
          },
          "k": (state) => {
            moveCursorToAdjacentVerse(() => [state.selection.$anchor, -1]);
            return true;
          },

          "Alt-Enter"(state) {
            const { onNavigateToVerse } = binding.current;

            if (typeof onNavigateToVerse === "function") {
              const verseId = findVerseIdAtCursor(state);

              if (verseId) {
                onNavigateToVerse(verseId);
                return true;
              }
            }

            return false;
          },

          "Alt-ArrowUp"() {
            const { onNavigateToParentNode } = binding.current;

            if (onNavigateToParentNode) {
              onNavigateToParentNode();
              return true;
            }

            return false;
          },

          "Space": trackMistakeInVerseAtCursor,
        }),
      ],
    });
  }, []);

  return (
    <>
      <KeyBinding
        defaultKey={"Space"}
        onTrigger={trackMistakeInVerseAtCursor}
      />
    </>
  );
}
