.container {
  display: flex;
  gap: 0 0.5rem;
  margin-bottom: 0.5rem;
  opacity: 0.3;
  transition: opacity 0.2s ease-in-out;
}

.container:hover,
.container:focus {
  opacity: 1;
}
