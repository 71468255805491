import { ErrorBoundary } from "@muddakir/engine";
import { GenericErrorHandler } from "@muddakir/error-messages";
import { ScopeType } from "@muddakir/quran-verse-range";
import memoize from "memoize";
import React from "react";
import { WirdMushaf } from "./Mushaf";
import { isWirdCycle } from "./graph";

export default [
  {
    name: "wird",
    path: "/wird",
    queryParams: {
      ce: {},
      cycle: {},
      cycleData: {},
    },
    component: React.lazy(() => import("./Wird")),
  },
  {
    path: "/wird/:cycle/:session/mistakes",
    component: React.lazy(() => import("./Mistakes")),
  },
  {
    path: ({ url }: { url: string }) => resilientPath(url),
  },
];

const resilientPath = memoize(path);

function path(url: string) {
  const fragments = url.split("/");

  if (
    fragments &&
    isWirdCycle(fragments[0]!) &&
    !Number.isNaN(parseInt(fragments[1]!, 10))
  ) {
    return {
      component: (props: any) => (
        <ErrorBoundary fallback={GenericErrorHandler}>
          <WirdMushaf {...props} />
        </ErrorBoundary>
      ),
      params: {
        cycle: fragments[0],
        session: parseInt(fragments[1]!, 10),
        path: fragments,
        scopeType: ScopeType.SINGLE,
      },
    };
  } else {
    return null;
  }
}
