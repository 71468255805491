import EmbeddingsMainMenu from "@muddakir/embeds/menu";
import { MainMenuItem, usePreference } from "@muddakir/engine";
import { FolderMainMenuItems } from "@muddakir/folders";
import M10tMainMenuItems from "@muddakir/m10t/menu";
import NotesMainMenuItems from "@muddakir/notes/menu";
import { Unit } from "@muddakir/quran-db";
import { SearchMainMenuItems } from "@muddakir/search/react";
import VerseGroupingMainMenuItems from "@muddakir/verse-grouping/menu";
import { groupingUnitPreference } from "@muddakir/verse-grouping/preferences";
import VerseMarkersMainMenuItems from "@muddakir/verse-markers/menu";

export default function MainMenuItems({
  dispatchKeyToEditor,
  verseMarkers,
}: {
  dispatchKeyToEditor: (init: KeyboardEventInit) => () => boolean;
  verseMarkers: VerseMarker[];
}) {
  const [groupBy, setGroupBy] = usePreference(groupingUnitPreference);

  return (
    <>
      <FolderMainMenuItems />
      <SearchMainMenuItems />
      <VerseGroupingMainMenuItems />
      <VerseMarkersMainMenuItems
        onInsert={dispatchKeyToEditor({ key: "1" })}
        onRemove={dispatchKeyToEditor({ key: "2" })}
        onClear={dispatchKeyToEditor({ key: "0" })}
      />

      <NotesMainMenuItems onInsert={dispatchKeyToEditor({ key: "Enter" })} />

      <M10tMainMenuItems onInsert={dispatchKeyToEditor({ key: "m" })} />

      <EmbeddingsMainMenu verseMarkers={verseMarkers} />

      <MainMenuItem
        path={["View", "Group by", "Custom Chain"]}
        onTrigger={() => {
          setGroupBy(Unit.Custom);
          return true;
        }}
        value={groupBy === Unit.Custom}
        type="checkbox"
      />
    </>
  );
}
