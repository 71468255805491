import { MainMenuItem, RoutingContext } from "@muddakir/engine";
import React from "react";

interface NodeRel {
  href: string;
}

export const Jumper = ({
  prevNode,
  prevCousinNode,
  nextNode,
  nextCousinNode,
  parentNode,
}: {
  prevNode: NodeRel | null;
  prevCousinNode?: NodeRel | null;
  nextNode: NodeRel | null;
  nextCousinNode?: NodeRel | null;
  parentNode: NodeRel | null;
}) => {
  const { navigate } = React.useContext(RoutingContext);
  const navigateToNode = (node: NodeRel | null) => () => {
    if (!node) {
      return false;
    }
    navigate(node.href);
    return true;
  };

  return (
    <>
      <MainMenuItem
        path={["Go", "Jump to Next Group"]}
        onTrigger={navigateToNode(nextNode)}
        keyBinding="ArrowLeft"
        disabled={!nextNode}
      />

      {nextCousinNode && (
        <MainMenuItem
          path={["Go", "Jump to Next Outer Group"]}
          onTrigger={navigateToNode(nextCousinNode)}
          keyBinding="Ctrl+ArrowLeft"
          disabled={!nextCousinNode}
        />
      )}

      <MainMenuItem
        path={["Go", "Jump to Previous Group"]}
        onTrigger={navigateToNode(prevNode)}
        keyBinding="ArrowRight"
        disabled={!prevNode}
      />

      {prevCousinNode && (
        <MainMenuItem
          path={["Go", "Jump to Previous Outer Group"]}
          onTrigger={navigateToNode(prevCousinNode)}
          keyBinding="Ctrl+ArrowRight"
          disabled={!prevCousinNode}
        />
      )}

      <MainMenuItem
        path={["Go", "Jump to Outer Group"]}
        onTrigger={navigateToNode(parentNode)}
        keyBinding="Alt+ArrowUp"
        disabled={!parentNode}
      />
    </>
  );
};
