import { GraphContext, useForceReload, usePreference } from "@muddakir/engine";
import { persistGraph } from "@muddakir/persistence";
import { useContext, useEffect, useRef, useState } from "react";
import { addTimeToSession, getTimeElapsedInSession } from "../graph";
import { wirdSessionAutoStartStopwatchPreference } from "../preferences";

export function useTimer({
  cycle,
  session,
}: {
  cycle: GraphNodeId;
  session: number;
}) {
  const { graph } = useContext(GraphContext);
  const [autoStart] = usePreference(wirdSessionAutoStartStopwatchPreference);
  const [enabled, enable] = useState<boolean>(autoStart);
  const elapsed = useRef<number>(0);
  const enabledRef = useRef<boolean>(enabled);
  const forceReload = useForceReload();
  enabledRef.current = enabled;

  useEffect(() => {
    const tick = setInterval(() => {
      if (enabledRef.current && document.visibilityState !== "hidden") {
        elapsed.current += 1;
        forceReload();
      }
    }, 1000);

    return () => {
      clearInterval(tick);
    };
  }, []);

  useEffect(() => {
    const save = () => {
      addTimeToSession(graph, cycle, session, elapsed.current);
      persistGraph(graph);
      elapsed.current = 0;
    };

    document.addEventListener("visibilitychange", save);
    window.addEventListener("beforeunload", save);

    return () => {
      if (elapsed.current > 1) {
        save();
      }

      document.removeEventListener("visibilitychange", save);
      window.removeEventListener("beforeunload", save);
    };
  }, []);

  return {
    elapsed: getTimeElapsedInSession(graph, cycle, session) + elapsed.current,
    stop: () => enable(false),
    start: () => enable(true),
    running: enabled,
  };
}
