import { useMemo } from "react";
import { LayoutProps, SelectionProps } from ".";

export function useProgress({
  cycle,
  session,
  path,
  node,
  tree,
}: {
  cycle: SelectionProps["cycle"];
  session: SelectionProps["session"];
  path: SelectionProps["path"];
  node: LayoutProps["node"];
  tree: LayoutProps["tree"];
}): SessionProgress | null {
  return useMemo(() => {
    if (!node) {
      return null;
    }

    const firstVerseInCurrentUnit = node.verses.at(0);
    if (!firstVerseInCurrentUnit) {
      return null;
    }

    const sessionVerses = tree.find([cycle!, String(session)])!.verses;

    const verseCount = sessionVerses.length;
    const currentIndex = sessionVerses.indexOf(firstVerseInCurrentUnit);

    return { total: verseCount, current: currentIndex };
  }, [path]);
}

export function ProgressBar({
  progress,
}: {
  progress: SessionProgress | null;
}) {
  if (!progress) {
    return null;
  }

  return (
    <div style={{ flexGrow: 0 }}>
      <progress
        style={{ width: "100%" }}
        value={progress.current + 1}
        max={progress.total}
      />
    </div>
  );
}

export interface SessionProgress {
  total: number;
  current: number;
}
