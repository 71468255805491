/** @jsxImportSource pmjsx */

import { MuddakirPlugin } from "#plugins";
import { getVerseText, Unit } from "@muddakir/quran-db";
import { ScopeType } from "@muddakir/quran-verse-range";
import { VerseLinkAttrs } from "@muddakir/verse-anchors";
import { VerseNumber } from "@muddakir/verse-anchors/pm/blocks";
import { borderLabelFor, shouldGroup } from "@muddakir/verse-grouping";
import { VerseGroupAndVerses } from "@muddakir/verse-grouping/types";
import AbstractGraph from "graphology-types";
import { VerseInWirdCycleSession } from "../graph";

type GroupAndVerses = VerseGroupAndVerses<
  VerseInWirdCycleSession & VerseLinkAttrs
>;

type Props = {
  graph: AbstractGraph;
  groupingUnit: Unit | null | undefined;
  nodeType: Unit | null;
  plugins: MuddakirPlugin[];
  scopeType: ScopeType;
};

export default (props: Props, groups: GroupAndVerses[]) => {
  const nodes: JSX.Element[] = [];

  const shouldGroupVerses = shouldGroup({
    nodeType: props.nodeType,
    groupingUnit: props.groupingUnit,
    scopeType: props.scopeType,
    groups,
  });

  for (const [index, groupAndVerses] of groups.entries()) {
    const groupVerses = (
      <GroupVerses
        {...props}
        groupAndVerses={groupAndVerses}
      />
    );

    if (!shouldGroupVerses) {
      nodes.push(<pm:generic-container>{groupVerses}</pm:generic-container>);
    } else if (groupAndVerses.group === null) {
      nodes.push(groupVerses);
    } else {
      const { group } = groupAndVerses;

      nodes.push(
        <pm:verse-group
          id={group.id}
          // @ts-expect-error
          type={group.type /* TODO: what is this supposed to map to? */}
          className={{
            "verse-group--spaced": true,
            "verse-group--lead": index === 0,
            "verse-group--separated": true,
          }}
        >
          {groupVerses}

          {group.href && (
            <pm:verse-group-anchor
              href={group.href!}
              altHref={group.altHref}
            >
              {`${borderLabelFor(group) || group.id}`}
            </pm:verse-group-anchor>
          )}
        </pm:verse-group>,
      );
    }
  }

  return nodes;
};

function GroupVerses({
  groupAndVerses: { verses },
  groupingUnit,
  nodeType,
}: Props & { groupAndVerses: GroupAndVerses }) {
  const content: JSX.Node[] = [];

  for (const verse of verses) {
    content.push(
      <pm:verse id={verse.id}>{getVerseText(verse.id)}</pm:verse>,
      (verse.mistakes.length && <pm:verse-mistake-emblem />) || null,
      <VerseNumber
        anchor={undefined}
        nodeType={nodeType}
        groupingUnit={groupingUnit}
        verse={verse}
      />,
    );
  }

  return (
    <>
      <pm:justified>{content}</pm:justified>
    </>
  );
}
