import { lazy } from "react";

export default [
  {
    path: "/graph",
    component: lazy(() =>
      import("./index").then((x) => ({ default: x.GraphExplorer })),
    ),
  } as RouteSpec,
  {
    path: "/graph/:node",
    queryParams: {
      degree: {} as QueryParamSpec<string>,
    },
    component: lazy(() =>
      import("./index").then((x) => ({ default: x.GraphExplorer })),
    ),
  } as RouteSpec,
];
