import {
  GraphContext,
  MainMenuItem,
  RoutingContext,
  t,
  usePreference,
} from "@muddakir/engine";
import Layout from "@muddakir/layout";
import { MushafLayout } from "@muddakir/mushaf/layout";
import Nav, { NodeRel } from "@muddakir/mushaf/nav";
import { ProseMirrorFocusState } from "@muddakir/prosemirror-focus-state";
import {
  classify,
  isValidVerseId,
  Unit,
  unitsBetween,
} from "@muddakir/quran-db";
import { QuranSelector, ScopeType } from "@muddakir/quran-verse-range";
import { VerseTree, VerseTreeNode } from "@muddakir/quran-verse-tree";
import { Scribe, useScribe } from "@muddakir/scribe";
import { SearchMainMenuItems } from "@muddakir/search/react";
import { ProseMirrorVerseAnchors } from "@muddakir/verse-anchors/pm/react";
import VerseGroupingMainMenuItems from "@muddakir/verse-grouping/menu";
import { ProseMirrorVerseGrouping } from "@muddakir/verse-grouping/pm/react";
import { groupingUnitPreference } from "@muddakir/verse-grouping/preferences";
import { Jumper } from "@muddakir/verse-jumping";
import { ProseMirrorVerseJumping } from "@muddakir/verse-jumping/pm/react";
import { ProseMirrorQuran } from "prosemirror-quran-schema/react";
import { useContext, useMemo } from "react";
import { MdFlag } from "react-icons/md";
import { ProseMirror } from "react-prosemirror";
import { getMistakeInUnit, Mistake, trackMistakeInUnit } from "../graph";
import { ProseMirrorWird } from "../pm/react";
import {
  wirdSessionProgressPreference,
  wirdSessionStopwatchPreference,
} from "../preferences";
import { WirdMushafKeyBindings } from "./keys";
import { ProgressBar, useProgress } from "./progress";
import { Stopwatch } from "./stopwatch";
import { useWirdDocument } from "./target";

export interface SelectionProps {
  cycle: GraphNodeId;
  session: number;
  path: QuranSelector[];
  scopeType: ScopeType.SINGLE;
}

export interface LayoutProps {
  doc: object | null;
  node: VerseTreeNode | null;
  tree: VerseTree;
  error: string | null;
}

export function WirdMushaf({
  cycle,
  session,
  path,
  scopeType,
}: SelectionProps) {
  const [groupingUnit] = usePreference(groupingUnitPreference);
  const scribe = useScribe();
  const { navigate } = useContext(RoutingContext);
  const { graph, graphState } = useContext(GraphContext);
  const [showProgress, toggleProgress] = usePreference(
    wirdSessionProgressPreference,
  );
  const [showStopwatch, toggleStopwatch] = usePreference(
    wirdSessionStopwatchPreference,
  );
  const { doc, node, tree, error } = useWirdDocument({
    cycle,
    session,
    path,
    scopeType,
    groupingUnit,
  });

  const progress = useProgress({
    cycle,
    node,
    path,
    session,
    tree,
  });

  const mistake = useMemo(() => {
    if (node?.id && isValidVerseId(node.id)) {
      return getMistakeInUnit(graph, cycle, session, node.id);
    } else {
      return null;
    }
  }, [node?.id, graphState]);

  if (error) {
    return (
      <Layout>
        <pre>{error}</pre>
      </Layout>
    );
  }

  if (!node || !doc) {
    return <Layout>No verses were found at this address.</Layout>;
  }

  let parentNodeRel: NodeRel | null;

  if (node.parent === node.root) {
    parentNodeRel = {
      text: t("Wird Cycle", "الدورة"),
      href: `#/wird?cycle=${cycle}`,
    };
  } else if (node.root !== node) {
    let text: string;

    if (node.parent!.id === String(session)) {
      text = t(`Session ${session}`, `الجلسة (${session})`);
    } else if (node.parent === node.root) {
      text = scribe.abbrevRoot(node.parent, scopeType)!;
    } else {
      text = scribe.describe(node.parent!)!;
    }

    parentNodeRel = {
      text,
      href: `${genHref(node.parent!)}?a=${node.id}`,
    };
  } else {
    parentNodeRel = null;
  }

  const nextNodeRel = node.next ? genNavUnit(scribe, node.next) : null;
  const prevNodeRel = node.prev ? genNavUnit(scribe, node.prev) : null;
  const nextCousinNodeRel = computeCousinNodeRel({
    dir: 1,
    node,
    scribe,
    tree,
  });

  const prevCousinNodeRel = computeCousinNodeRel({
    dir: -1,
    node,
    scribe,
    tree,
  });

  return (
    <Layout>
      <SearchMainMenuItems />
      <VerseGroupingMainMenuItems />

      <MainMenuItem path={["View", "Wird"]} />
      <MainMenuItem
        path={["View", "Wird", "Progress"]}
        onTrigger={() => {
          toggleProgress(!showProgress);
          return true;
        }}
        type="checkbox"
        value={showProgress}
      />
      <MainMenuItem
        path={["View", "Wird", "Stopwatch"]}
        onTrigger={() => {
          toggleStopwatch(!showStopwatch);
          return true;
        }}
        type="checkbox"
        value={showStopwatch}
      />

      <MushafLayout
        navSlot={
          <Nav
            nextNode={nextNodeRel}
            prevNode={prevNodeRel}
            parentNode={parentNodeRel}
          />
        }
        leadSlot={
          node.parent !== node.root && (
            <>
              {showProgress && <ProgressBar progress={progress} />}

              {showStopwatch && (
                <Stopwatch
                  cycle={cycle}
                  session={session}
                  progress={progress}
                />
              )}
            </>
          )
        }
        titleSlot={
          <>
            {node.id === String(session)
              ? t(`Session ${session}`, `الجلسة (${session})`)
              : scribe.describe(node)}

            {mistake && (
              <MdFlag
                color="red"
                style={{ verticalAlign: "middle" }}
              />
            )}
          </>
        }
      >
        <ProseMirror
          debug
          doc={doc}
          docId={JSON.stringify(path)}
        >
          <ProseMirrorQuran />
          <ProseMirrorVerseGrouping />
          <ProseMirrorVerseAnchors anchor={undefined} />
          <ProseMirrorVerseJumping />
          <ProseMirrorFocusState />
          <ProseMirrorWird />

          <WirdMushafKeyBindings
            onTrackMistakeInCurrentUnit={(verse: VerseId) => {
              const mistake = getMistakeInUnit(graph, cycle, session, verse);
              return trackMistakeInUnit(
                graph,
                cycle,
                session,
                verse,
                mistake ? null : Mistake.Indeterminate,
                null,
              );
            }}
            onNavigateToVerse={(id) =>
              navigate(`/${path.concat([id]).join("/")}`)
            }
            onNavigateToParentNode={() =>
              parentNodeRel ? navigate(parentNodeRel.href) : Promise.reject()
            }
          />
        </ProseMirror>

        <Jumper
          nextNode={nextNodeRel}
          nextCousinNode={nextCousinNodeRel}
          prevNode={prevNodeRel}
          prevCousinNode={prevCousinNodeRel}
          parentNode={parentNodeRel}
        />
      </MushafLayout>
    </Layout>
  );
}

function computeCousinNodeRel({
  dir,
  node,
  scribe,
  tree,
}: {
  dir: 1 | -1;
  node: VerseTreeNode;
  tree: VerseTree;
  scribe: Scribe;
}): NodeRel | null {
  if (!tree || !node.parent) {
    return null;
  }

  const uncle = dir === 1 ? node.parent.next : node.parent.prev;
  if (!uncle) {
    return null;
  }

  let typeOfCurrentUnit = classify(node.id);
  if (!typeOfCurrentUnit) {
    return null;
  }

  const [firstVerse] = uncle.verses;
  if (!firstVerse) {
    return null;
  }

  const [firstUnit] =
    typeOfCurrentUnit === Unit.Verse
      ? [firstVerse, firstVerse]
      : unitsBetween(typeOfCurrentUnit)([firstVerse, firstVerse]);

  const cousinNode = tree.find(uncle.path.concat([firstUnit]));
  if (!cousinNode) {
    return null;
  }

  return genNavUnit(scribe, cousinNode);
}

const genHref = (node: VerseTreeNode) => `#/${node.path.join("/")}`;
const genNavUnit = (scribe: Scribe, node: VerseTreeNode) => ({
  text: scribe.describeAmongSiblings(node)!,
  href: genHref(node),
});
