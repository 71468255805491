export const wirdSessionProgressPreference = {
  name: "wsProgress",
  defaultValue: true,
};

export const wirdSessionStopwatchPreference = {
  name: "wsStopwatch",
  defaultValue: true,
};

export const wirdSessionAutoStartStopwatchPreference = {
  name: "wsAutoStartStopwatch",
  defaultValue: true,
};
