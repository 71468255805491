import { MainMenuItem } from "@muddakir/engine";
import { keymap } from "prosemirror-keymap";
import { TextSelection } from "prosemirror-state";
import { useContext, useEffect } from "react";
import { ProseMirrorContext } from "react-prosemirror";

export const ProseMirrorFocusState = () => {
  const { register, view } = useContext(ProseMirrorContext);

  useEffect(() => {
    return register({
      plugins: [
        keymap({
          Escape: (_state, _dispatch, view) => {
            if (view?.hasFocus()) {
              view.dom.blur();
              return true;
            }
            return false;
          },
        }),
      ],
    });
  }, []);

  return (
    <MainMenuItem
      path={["Edit", "Focus Editor"]}
      onTrigger={() => {
        if (!view || view.hasFocus()) {
          return false;
        }

        view.focus();

        if (view.state.selection.$anchor.nodeAfter === null) {
          view.dispatch(
            view.state.tr
              .setSelection(TextSelection.atStart(view.state.doc))
              .scrollIntoView(),
          );
        }

        return true;
      }}
      keyBinding="Alt+Enter"
    />
  );
};
