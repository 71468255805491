import { ProseMirrorAppendixes } from "@muddakir/appendixes/pm/react";
import { GraphContext, usePreference } from "@muddakir/engine";
import Layout, { sidebarEnabledPreference } from "@muddakir/layout";
import { MushafLayout } from "@muddakir/mushaf/layout";
import Nav from "@muddakir/mushaf/nav";
import { editNoteFor } from "@muddakir/notes/pm";
import { ProseMirrorNotes } from "@muddakir/notes/react";
import { NotesInURL } from "@muddakir/notes/useNotesInURL";
import { QuranSelector, ScopeType } from "@muddakir/quran-verse-range";
import { VerseTreeNode } from "@muddakir/quran-verse-tree";
import { Scribe } from "@muddakir/scribe";
import { ProseMirrorVerseAnchors } from "@muddakir/verse-anchors/pm/react";
import { ProseMirrorVerseGrouping } from "@muddakir/verse-grouping/pm/react";
import { Jumper } from "@muddakir/verse-jumping";
import { VerseSliceNode } from "@muddakir/verse-slice-editor";
import { ProseMirrorVerseSliceEditor } from "@muddakir/verse-slice-editor/react";
import AbstractGraph from "graphology-types";
import { keymap } from "prosemirror-keymap";
import { ProseMirrorQuran } from "prosemirror-quran-schema/react";
import { ancestors } from "prosemirror-util";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  createStateContainerPlugin,
  ProseMirror,
  ProseMirrorContext,
} from "react-prosemirror";
import { adjustRange, dropParticipant } from "../graph";
import { GroupEditor } from "../groupEditor";
import m10tExtension from "../pm";
import m10tSchema from "../schema";
import MainMenuItems from "./menu";
import Sidebar from "./sidebar";
import { useM10tDocument } from "./target";

export interface M10tTargetProps {
  id: GraphNodeId;
  path: QuranSelector[];
  scopeType: ScopeType.SINGLE;
  editedVerse: GraphEdgeId | undefined;
}

export interface VerseM10tAttrs {
  edge?: GraphEdgeId;
  marker?: VerseMarker;
}

export interface SelectionProps {
  id: GraphNodeId;
  path: QuranSelector[];
  scopeType: ScopeType.SINGLE;
}

export interface LayoutProps {
  doc: object | null;
  node: VerseTreeNode | null;
  notes: NotesInURL;
  scribe: Scribe;
}

export function M10tMushaf(props: SelectionProps) {
  const [editedVerse, editVerse] = useState<GraphEdgeId | undefined>(undefined);
  const {
    // doc,
    // node,
    id,
    path,
    scopeType,
  } = props;
  const { error, node, doc, notes, scribe } = useM10tDocument({
    path,
    id,
    scopeType,
    editedVerse,
  });

  const { graph } = React.useContext(GraphContext);
  const [sidebarEnabled] = usePreference(sidebarEnabledPreference);
  const {
    describe,
    describeForTitle,
    describeAmongSiblings,
    describeRoot,
    abbrevRoot,
  } = scribe;

  useEffect(() => {
    if (node) {
      document.title = describeForTitle(node);
    }
  }, [node]);

  if (!node || !doc) {
    return <Layout />;
  } else if (error) {
    return (
      <Layout>
        <pre>{error}</pre>
      </Layout>
    );
  }

  const genHref = (node: VerseTreeNode) => `#/${node.path.join("/")}`;
  const genNavUnit = (node: VerseTreeNode) => ({
    text: describeAmongSiblings(node)!,
    href: genHref(node),
  });

  // TODO: what happens to @href when node.parent is null?
  const parentNodeRel: { text: string; href: string } | null =
    node.root !== node
      ? {
          text:
            node.parent === node.root
              ? abbrevRoot(node.parent, scopeType)!
              : describe(node.parent!)!,
          href: `${genHref(node.parent!)}?a=${node.id}`,
        }
      : null;

  const nextNodeRel = node.next ? genNavUnit(node.next) : null;
  const prevNodeRel = node.prev ? genNavUnit(node.prev) : null;

  return (
    <Layout>
      <MainMenuItems />

      <Jumper
        nextNode={nextNodeRel}
        prevNode={prevNodeRel}
        parentNode={parentNodeRel}
      />

      <MushafLayout
        sidebarSlot={sidebarEnabled && <Sidebar id={props.id} />}
        titleSlot={
          isRoot(node) ? describeRoot(node, scopeType) : describe(node)
        }
        navSlot={
          <Nav
            nextNode={nextNodeRel}
            prevNode={prevNodeRel}
            parentNode={parentNodeRel}
          />
        }
      >
        <ProseMirror doc={doc}>
          <KeyBindings
            graph={graph}
            editNote={notes.editNote}
            editVerse={editVerse}
          />

          <ProseMirrorQuran />
          <ProseMirrorVerseGrouping />
          <ProseMirrorAppendixes />
          <ProseMirrorVerseAnchors anchor={undefined} />
          <ProseMirrorM10t
            setM10tEdit={() => {}}
            graph={graph}
            m10tEdit={undefined}
          />

          <ProseMirrorNotes
            graph={graph}
            note={notes.note}
            editNote={notes.editNote}
            verseMarkers={[]}
          />

          <ProseMirrorVerseSliceEditor
            onRead={() => {
              editVerse(undefined);
            }}
            onChange={(edge: GraphEdgeId, marker: VerseMarker) => {
              adjustRange(graph, edge, marker.slice(1) as [number, number]);
              editVerse(undefined);
            }}
            onRemove={(edge: GraphEdgeId) => {
              dropParticipant(graph, edge);
            }}
          />
        </ProseMirror>

        {node.root === node && (
          <GroupEditor
            className="mushaf-m10t-group-editor"
            graph={graph}
            group={id}
            autoFocus={false}
            onDismiss={() => {}}
          />
        )}
      </MushafLayout>
    </Layout>
  );
}

const isRoot = (node: VerseTreeNode) => node.root === node;

type KeyBindingsProps = {
  graph: AbstractGraph;
  editVerse: (id: VerseId | undefined) => void;
  editNote: (edge: GraphEdgeId | null) => void;
};

function KeyBindings(props: KeyBindingsProps) {
  const { register } = useContext(ProseMirrorContext);
  // const [bus, binding] = createStateContainerPlugin<KeyBindingsProps>(props);
  const binding = useRef(props);

  binding.current = props;

  useEffect(() => {
    return register({
      plugins: [
        // bus,
        keymap({
          Enter(state) {
            return ancestors(state.doc, state.selection.anchor, (node) => {
              if (node.type.name === "verseSlice") {
                const { editVerse } = binding.current;

                editVerse(node.attrs.edge);

                return true;
              }

              return false;
            });
          },

          n(state) {
            let verseSlice: VerseSliceNode | undefined;

            ancestors(state.doc, state.selection.anchor, (node) => {
              if (node.type.name === "verseSlice") {
                verseSlice = node as VerseSliceNode;
                return true;
              }

              return false;
            });

            if (!verseSlice) {
              return false;
            }

            const { editNote, graph } = binding.current;

            return editNoteFor(
              {
                graph,
                editNote,
              },
              verseSlice.attrs.edge,
              null,
            );
          },
        }),
      ],
    });
  }, []);

  return null;
}

type ProseMirrorM10tProps = {
  graph: AbstractGraph;
  m10tEdit: GraphEdgeId | undefined;
  setM10tEdit: (nextValue: GraphEdgeId | null) => void;
};

function ProseMirrorM10t(props: ProseMirrorM10tProps) {
  const { register, view } = useContext(ProseMirrorContext);
  const [bus, busKey] = createStateContainerPlugin<ProseMirrorM10tProps>(props);

  useEffect(() => {
    return register({
      schema: {
        // @ts-expect-error
        nodes: {
          // ...appendixes.nodes,
          // ...folderSchema.nodes,
          ...m10tSchema.nodes,
          // ...notesSchema.nodes,
          // ...vaSchema.nodes,
          // ...verseWRF.nodes,
          // ...vgSchema.nodes,
          // ...vmSchema.nodes,
          // ...vseSchema.nodes,
        },
        // @ts-expect-error
        marks: {
          // ...appendixes.marks,
          // ...folderSchema.marks,
          ...m10tSchema.marks,
          // ...notesSchema.marks,
          // ...vaSchema.marks,
          // ...verseWRF.marks,
          // ...vgSchema.marks,
          // ...vmSchema.marks,
          // ...vseSchema.marks,
        },
      },
      plugins: [bus],
      nodeViews: {
        ...m10tExtension.nodeViews(busKey),
      },
    });
  }, []);

  useEffect(() => {
    if (view) {
      view.dispatch(view.state.tr.setMeta(busKey, props));
    }
  }, [...Object.values(props)]);

  return null;
}
