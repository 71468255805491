import { MainMenuItem, usePreference } from "@muddakir/engine";
import { Unit } from "@muddakir/quran-db";
import { groupingUnitPreference } from "./preferences";

export default function VerseGroupingMainMenuItems({
  group: [groupingUnit, setGroupBy] = usePreference(groupingUnitPreference),
}: {
  group?: [Unit, (unit: Unit | null) => void];
}) {
  return (
    <>
      <MainMenuItem path={["View", "Group by"]} />
      <MainMenuItem
        path={["View", "Group by", "None"]}
        onTrigger={() => {
          setGroupBy(null);
          return true;
        }}
        value={groupingUnit === null}
        type="checkbox"
        keyBinding="5"
      />

      <MainMenuItem
        path={["View", "Group by", "Chapter"]}
        onTrigger={() => {
          setGroupBy(Unit.Chapter);
          return true;
        }}
        value={groupingUnit === Unit.Chapter}
        type="checkbox"
        keyBinding="6"
      />

      <MainMenuItem
        path={["View", "Group by", "Page in Mushaf"]}
        onTrigger={() => {
          setGroupBy(Unit.Page);
          return true;
        }}
        value={groupingUnit === Unit.Page}
        type="checkbox"
      />

      <MainMenuItem
        path={["View", "Group by", "Rub'"]}
        onTrigger={() => {
          setGroupBy(Unit.Quarter);
          return true;
        }}
        value={groupingUnit === Unit.Quarter}
        type="checkbox"
      />

      <MainMenuItem
        path={["View", "Group by", "Hizb"]}
        onTrigger={() => {
          setGroupBy(Unit.Hizb);
          return true;
        }}
        value={groupingUnit === Unit.Hizb}
        type="checkbox"
      />

      <MainMenuItem
        path={["View", "Group by", "Juz'"]}
        onTrigger={() => {
          setGroupBy(Unit.Juz);
          return true;
        }}
        value={groupingUnit === Unit.Juz}
        type="checkbox"
      />

      <MainMenuItem
        path={["View", "Group by", "Verse"]}
        onTrigger={() => {
          setGroupBy(Unit.Verse);
          return true;
        }}
        value={groupingUnit === Unit.Verse}
        type="checkbox"
      />
    </>
  );
}
